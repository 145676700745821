import React from "react";
import Seo from "../../components/seo";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import ogImage from "../../images/og.png";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import BlogCtaBanner from "../../components/BlogCtaBanner";

const GartnerEventPage = ({ location, data }) => {
    const GartnerEventPage = useStaticQuery(graphql`
        {
            eventPage2: contentfulEventPages(slug: { eq: "gartner-it-symposium-2023" }) {
                id
                slug
                title
                description {
                    description
                }
                meetusCtaText
                eventBannerImage {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                }
                eventClientHeading
                eventClientDescription {
                    eventClientDescription
                }
                eventClientsLogo {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    title
                    file {
                        url
                    }
                }
                eventTitle
                eventDescription {
                    eventDescription
                }
                chatSectionTitle
                chatSectionDescription {
                    raw
                }
                meetOurTeamTitle
                eventMembers {
                    name
                    designation
                    colorLogo {
                        gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                        title
                        file {
                            url
                        }
                    }
                }
                eventVenueAddress {
                    eventVenueAddress
                }
                eventVenueDate
                eventVenueImage {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                    title
                    file {
                        url
                    }
                }
                eventFooter {
                    ctaText
                    header
                    ctaSlug
                }
            }
        }
    `);
    // const eventdata = GartnerEventPage.allContentfulEventPages.nodes[1];
    const eventdata = GartnerEventPage.eventPage2;
    console.log(eventdata);
    const bgImage = eventdata.eventBannerImage?.gatsbyImageData?.images.fallback.src;
    const NavbarTheme = {
        color: "light",
        paddingTop: "0px",
    };
    const options = {
        renderNode: {
            // [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            [BLOCKS.UL_LIST]: (node, children) => {
                return <ul>{children}</ul>;
            },
            [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
            [BLOCKS.LIST_ITEM]: (node, children) => (
                <li className="text-p3  text-clr-secondary text-fw-regular gap-1x">{children}</li>
            ),
        },
    };
    return (
        <Layout NavbarTheme={NavbarTheme}>
            <Seo
                title={" Events - Gartner IT Symposium/Xpo™ 2023"}
                description={
                    "Get to know details about all the exciting events, technology conferences, business gatherings and more our team will be heading to. Explore our schedule."
                }
                url={location.href}
                image={`https://sedintechnologies.com${ogImage}`}
            />
            {/* Header section  */}

            {/* event-details-landing page-hero section */}
            <section
                className="events-details-hero"
                style={{
                    background: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                }}
            >
                {/* <div className="container container--mid-v6"> */}
                <div className="container container--banner">
                    <div className="event-details__text">
                        <h1 className="text-h1 title text-fw-medium">{eventdata.title}</h1>
                        <div className="event-details__text-partner">
                            <div className="partner-summit-one">
                                <h3 className="text-p1 gap-2x date text-fw-regular">
                                    November 28 - 30 <span> | </span>
                                    {eventdata.description.description}
                                </h3>
                                <h3 className="text-p1 gap-2x desc">
                                    {/* {eventdata.description.description} */}
                                </h3>
                            </div>
                        </div>
                        <div className="flex">
                            <Link className="meet-at sdn-cta-btn" to="/contact/">
                                {eventdata.meetusCtaText}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="event-details-client">
                <div className="container container--first">
                    <div className="event-details-clients__grid">
                        <div className="event-details-clients__grid-text-block">
                            <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                                {eventdata.eventClientHeading}
                            </h2>
                            <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                                {eventdata.eventClientDescription.eventClientDescription}
                            </p>
                        </div>
                        {/* <p className="text-p3 text-clr-secondary text-fw-medium">Trusted by</p> */}
                        <div className="event-details-clients__grid-logo gap-2x">
                            {eventdata.eventClientsLogo.map((el, i) => (
                                <GatsbyImage
                                    key={i}
                                    image={el.gatsbyImageData}
                                    alt={el.title}
                                    loading="lazy"
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* we are proud sponser section */}
            <section className="event-details-proudsponser">
                <div className="container container--mid-v4">
                    <div className="event-details__text">
                        <h1 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            {eventdata.eventTitle}
                        </h1>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-2x">
                            {eventdata.eventDescription.eventDescription}
                        </p>
                    </div>
                </div>
            </section>
            {/* group chat points section */}
            <section className="event-details-group-chat-point">
                <div className="container container--mid-v4">
                    <div className="event-details__text">
                        <h1 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            {eventdata.chatSectionTitle}
                        </h1>
                        {renderRichText(eventdata.chatSectionDescription, options)}
                    </div>
                </div>
            </section>
            {/* meet out team section */}
            <section className="event-details-meet-our-team">
                <div className="container container--mid-v4">
                    <div className="event-details__text">
                        <h1 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                            {eventdata.meetOurTeamTitle}
                        </h1>
                    </div>
                    <div className="leaders__grid">
                        {eventdata.eventMembers.map((leader, index) => (
                            <div className="leaders__grid-item" key={index}>
                                <GatsbyImage
                                    image={leader.colorLogo?.gatsbyImageData}
                                    alt={leader.colorLogo?.title}
                                    className="leader-image"
                                    loading="lazy"
                                />
                                <p className="leader-name text-p1 text-clr-primary text-fw-medium">
                                    {leader.name}
                                </p>
                                <p className=" text-p4 text-clr-secondary text-fw-medium">
                                    {leader.designation}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* venue section */}
            <section className="event-details-venue">
                <div className="container container--mid-v4">
                    <div className="event-details__text ">
                        <div className="event-details__text-grid">
                            <div className="venue-address">
                                <h1 className="text-h1 text-fw-medium text-clr-primary gap-1x">
                                    Venue
                                </h1>
                                <div className="venue-address-main">
                                    <p className="text-p4 venue-address-street">
                                        {eventdata.eventVenueAddress.eventVenueAddress}
                                    </p>
                                </div>
                                <div className="venue-address-bottom">
                                    <p className="text-p4 venue-address-date">
                                        {eventdata.eventVenueDate}
                                    </p>
                                </div>
                            </div>
                            <div className="venue-img">
                                <GatsbyImage
                                    image={eventdata.eventVenueImage?.gatsbyImageData}
                                    alt={eventdata.eventVenueImage?.title}
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* service section  */}
            <section className="service-contact">
                <div className="container container--mid-v4">
                    <BlogCtaBanner
                        title={eventdata.eventFooter.header}
                        ctaLink={eventdata.eventFooter.ctaSlug}
                        ctaText={eventdata.eventFooter.ctaText}
                    />
                </div>
            </section>
        </Layout>
    );
};

export default GartnerEventPage;
