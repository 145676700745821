import React from "react";
import { Link } from "gatsby";

const BlogCtaBanner = ({ title, banner, ctaLink, ctaText }) => {
    return (
        <>
            <div className="cta-banner">
                <div className="cta-banner__grid-item">
                    <div className="cta-banner__grid-item__text">
                        <div className="text-h2 cta-text text-fw-medium">{title}</div>
                        <Link className=" sdn-cta-btn" to={ctaLink}>
                            {ctaText}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogCtaBanner;
